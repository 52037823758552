@import "../../../index.css";

.planSquare {
    border: solid 1px #d9d9d9;
    border-radius: 15px;
    padding: 2rem;
    margin: 2rem;
    background-color: #ffffff;
}

.planTitle {
    color: #FF6563;
    font-family: "Livvic Bold";
}

.planSubtitleSec {
    display: flex;
    align-items: baseline;
}

.planPrice {
    font-family: 'Inter Medium';
}

.planSubtitleSec p,
.planSubtitleSec span {
    font-family: "Inter Medium" !important;
}

.planSubtitleSec p {
    color: #2B2BCB;
}

.planSubtitleSec span {
    color: #2B2BCB80 !important;
}


.planInfo {
    color: #2B2BCB;
    font-size: 20px;
    font-family: "Inter Medium" !important;
}

.planSubtitleSec h2,
.planSubtitleSec p {
    margin: 0;
}

.planSubtitleSec p {
    font-size: 2rem;
    font-family: 'Inter Medium';
}

.planSubtitleSec span {
    margin-left: .7rem;
    color: #a0a0a0;
    font-family: 'Inter Medium';
}


.hr {
    color: #2B2BCB;
    opacity: 1;
}

.signUpBtn {
    font-family: 'Inter Medium';
    font-size: .8rem;
    background-color: #FF6563;
    border: #FF6563;
    color: whitesmoke;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .7rem;
    padding-bottom: .7rem;
}

.signUpBtn:active {
    background-color: #e14938 !important;
}

.signUpBtn:hover {
    background-color: #ff6f5f;
}

.planBodyTitle {
    color: #2B2BCB;
    font-family: 'Inter Medium';
}

.icon {
    color: #2B2BCB;
    font-size: 1.5rem;
}

.planBodyText,
.planBodySubtext {
    color: #2B2BCB;
    font-family: 'Inter Regular';
}

.planBodyText {
    font-size: 1rem;
}

.planBodySubtext {
    font-size: .7rem;
    color: #FF6563;
}

.planSubtitle {
    padding-left: 2.4rem;
}

.cancelTxt {
    font-size: 12px;
    color: #2B2BCB80
}

@media (width >=1400px) {
    .planInfo {
        padding-right: 10rem;
    }
}
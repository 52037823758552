@import '../../index.css';

/* ------ Fisrt Section ------ */
.sec1 {
    background-color: #2B2BCB;
}

.sec1Title {
    font-size: 30px;
    font-family: "Livvic Bold";
    color: white;
    text-align: center;
}

.sec1Subtitle {
    font-size: 16px;
    font-family: "Inter Regular";
    color: white;
    text-align: center;
}

/* ------ Third Section ------ */
.sec3{
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.sec3Title {
    font-size: 30px;
    font-family: "Livvic Bold";
    color: #FF6563;
    text-align: center;
    margin-bottom: 5rem;
}

.sec3ParagraphTitle {
    font-size: 20px;
    font-family: "Livvic Bold";
    color: #FF6563;
    text-align: center;
}

.sec3Paragraph {
    font-size: 16px;
    font-family: "Inter Regular";
    color: #FF6563;
    text-align: center;
}

/* ------ Fourd Section ------ */
.sec5Title {
    font-size: 30px;
    font-family: "Livvic Bold";
    color: #2B2BCB;
    text-align: center;
}

.faqSec{
    padding-top: 5rem;
    padding-bottom: 5rem;
}

@media (width >=768px) {

    .sec3ParagraphTitle,
    .sec3Paragraph {
        font-size: 20px;
        text-align: start;
    }
}

@media (width >=992px) {
    .sec1Subtitle {
        margin: auto;
    }

    .sec1Title,
    .sec3Title,
    .sec5Title {
        font-size: 50px;
    }

    .sec1Subtitle {
        font-size: 22px;
    }

    .sec3ParagraphTitle,
    .sec3Paragraph {
        font-size: 22px;
        text-align: start;
    }
}
@import "../../index.css";

.navbar {
    height: 4rem;
    background-color: #F8F6FA;
    border-bottom: 1px solid #D0D0D0;
}

.navbarWhite {
    height: 4rem;
    background-color: #FFFFFF;
    border-bottom: 1px solid #D0D0D0;
}

.brandContainer {
    display: flex;
    align-items: center;
}

.brand {
    font-size: 13px;
    font-family: 'Inter Regular';
    text-decoration: none;
    color: black;
}

.brand span {
    font-family: 'Inter Regular';
    background-color: #FBAA9C;
    color: whitesmoke;
    font-size: .5rem;
    border-radius: 100px;
    padding: 2.5px .5rem;
}

.imgLogo {
    width: 250px;
}

.offcanvasHeader {
    border-bottom: 1px solid #D9D9D9;
}

.offcanvasTitle {
    font-family: 'Inter Medium' !important;
    font-size: 1.5rem;
}

.sublinks {
    font-size: 1.3rem;
    text-decoration: none;
    font-family: 'Inter Medium' !important;
    color: #A6A6A6 !important;
    margin-bottom: 0.9em;
    padding-left: 0.5rem !important;
    background-color: transparent !important;
}

/* ---------------- mobile ---------------- */

.mobile {
    display: flex;
    justify-content: start !important;
}

.hamburgerIcon {
    background: none;
    border: none;
    color: #000000;
}

.mobileLinks {
    font-size: 1.3rem;
    text-decoration: none;
    color: black;
    font-family: 'Inter Medium' !important;
    line-height: 4rem;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #D9D9D9;
    text-align: start !important;
}

/* ---------------- desktop ---------------- */

.desktop {
    padding-inline: 5%;
}

.offcanvasNav {
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.links {
    font-size: .9rem;
    text-decoration: none;
    /* color: black; */
    font-family: 'Inter Medium' !important;
    padding: 5px 0;
    /* margin: 0; */
    /* text-align: start !important; */
    /* background: none; */
    /*  Para Safari 13 */
}

.links:hover {
    cursor: pointer;
    color: #2B2BCB;
}

.activeLink {
    border-bottom: 1px solid #FF6563 !important;
    border-radius: 0;
}

.navMenuItem {
    margin-inline: 0.8rem !important;
}

.userOptions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-block: 0.5em;
    padding-left: 0.5rem !important;
    font-size: .9rem;
}

.userOptions svg {
    margin-left: .2rem;
    margin-right: .8rem;
}

.dropdownDivider {
    margin-bottom: 0.9em;
}

.bgIcon a{
    background-color: #FF8CD11A;
    border-radius: 40px;
    padding: 10px;
}

@media (width >=768px) {
    .brand {
        font-size: .9rem;
    }

    .links {
        color: black;
        font-size: .9rem;
        border: none;
        line-height: 0;
    }

    .sublinks {
        font-size: .8rem;
    }
}

@media (width <=768px) {
    .sublinks {
        margin-bottom: 0.5em;
    }

    .imgLogo {
        width: 200px;
    }
}

@media (width > 992px) {
    .brand {
        font-size: 1.2rem;
    }

    .imgLogo {
        width: 190px;
    }
}

@media (width > 1200px) {
    .imgLogo {
        width: 200px;
    }
}